import { ComponentNames } from '@shared/constants/componentNames';

export { DemoCardSize } from './demo-card-size';

export const dynamicCards = [
  ComponentNames.basics_text,
  ComponentNames.basics_link,
  ComponentNames.basics_image,
  ComponentNames.basics_video,
  ComponentNames.basics_title,
  ComponentNames.basics_info_card,
  ComponentNames.basics_service_list,
];

export const allowedImages = [
  'image/png',
  'image/gif',
  'image/webp',
  'image/jpeg',
];

export const allowedVideos = ['video/mp4', 'video/quicktime'];

export const usernameRules = {
  validate: (value: string) => {
    if (value?.trim()?.length < 5) {
      return 'Please use 5 characters or more.';
    }
    if (value?.trim()?.length > 100) {
      return 'Please use no more than 100 characters.';
    }

    if (!/^[a-zA-Z0-9._-]+$/.test(value)) {
      return 'The username may only contain letters, numbers, dots, dashes and underscores.';
    }

    return true;
  },
};

export const PLATFORM_ALL = 30;

export const PLATFORM_TIKTOK = 2;

export const PLATFORM_YOUTUBE = 3;

export const PLATFORM_INSTAGRAM = 1;

export const defaultSlug = 'default';

export const getStartedStepsCount = 4;

export const DragHandler = 'DragHandler';

export const defaultEndColor = '#5956E9';

export const defaultStartColor = '#BF51E0';

export const BASE_URL = process.env.BASE_URL;

export const DraggableCancel = 'DraggableCancel';

export const profileAvatarIndex = 'profile-avatar-index';

export const blurDataURL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIwAAACMCAAAAACLqx7iAAAAaElEQVR42u3OAQ0AAAwCIPuXspo1vh0SkB4SGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRmZr5kBcBEiyLkGRrUAAAAASUVORK5CYII=';
