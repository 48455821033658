export enum ApiEndpoints {
  me = 'auth/me',
  login = 'auth/login',
  logout = 'auth/logout',
  verifyEmail = 'verify-email',
  contactCreator = 'contact/:username',
  contactCreatorService = 'contact/:username/service',
  registerStep2 = 'auth/register/step2',
  registerStep3 = 'auth/register/step3',
  checkUsername = 'auth/register/check',
  resetPassword = 'auth/reset-password',
  storeUsername = 'auth/register/step21',
  editMediakit = 'mediakit-builder/:slug',
  editMediakitFromTemplate = 'mediakit-builder/:slug/from-template/:templateId',
  forgotPassword = 'auth/forgot-password',
  countries = 'mediakit-builder/countries',
  getGoogleAuthUrl = 'auth/google/redirect',
  categories = 'mediakit-builder/categories',
  googleAuthCallback = 'auth/google/callback',
  enableMediaKit = 'mediakit-builder/:slug/enable',
  socialAccounts = 'search?q=:q&platform=:platform',
  uploadAvatar = 'mediakit-builder/:slug/upload-image',

  fetchComponents = 'mediakit-builder/components',

  linkCrawl = 'mediakit-builder/:slug/link-crawl',

  uploadImage = 'mediakit-builder/:slug/upload-image/component',
  uploadBackgroundImage = 'mediakit-builder/:slug/upload-image/background',

  fetchTemplate = 'templates/:id',
  mediakitBuilderTemplates = 'templates',

  createMediakit = 'mediakit-builder',
  fetchMediakits = 'mediakit-builder',
  fetchMediakit = 'mediakit-builder/:slug',
  uploadVideo = 'mediakit-builder/upload-video',
  updateMediakitFromTemplate = 'mediakit-builder/:slug/from-template/:templateId',
  fetchPublicMediakit = 'mediakit/:username',
  fetchThemeColors = 'mediakit-builder/theme-colors',
  fetchThemeColorPalettes = 'mediakit-builder/theme-color-palettes',
  themeUnsplashBgImages = 'mediakit-builder/theme-unsplash-bg-images',

  profileVerify = 'profile/verify',
  onboarding = 'profile/onboarding',
  startTour = 'profile/onboarding/start-tour',
  finishTour = 'profile/onboarding/finish-tour',
}
