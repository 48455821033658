import { api } from './api';
import { ApiEndpoints } from '@shared/constants/apiEndpoints';
import { transformErrorResponse } from '@/lib/utils/transformErrorResponse';

interface FetchCategoriesRT {
  data: { categories: { id: number; name: string }[] };
}

export const categoriesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchCategories: builder.mutation<FetchCategoriesRT, void>({
      transformErrorResponse,
      query: () => ({
        method: 'GET',
        url: ApiEndpoints.categories,
      }),
    }),
  }),
});

export const { endpoints, useFetchCategoriesMutation } = categoriesApi;
