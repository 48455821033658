import { ReduxState } from '@shared/redux';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

export const selectAppReducer = (state: ReduxState) => state.app;

export const selectSiderCollapsed = createDraftSafeSelector(
  selectAppReducer,
  (state) => state.siderCollapsed,
);

export const selectDrawerTheme = createDraftSafeSelector(
  selectAppReducer,
  (state) => state.drawerTheme,
);
