import { api } from './api';
import { Auth } from '@/lib/types/auth';
import { Forms } from '@/lib/types/forms';
import { ApiEndpoints } from '@shared/constants/apiEndpoints';
import { transformErrorResponse } from '@/lib/utils/transformErrorResponse';

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    me: builder.mutation<{ data: { user: Auth.AuthUser } }, void>({
      transformErrorResponse,
      query: () => ({
        method: 'GET',
        url: ApiEndpoints.me,
      }),
    }),
    login: builder.mutation<any, Forms.Login>({
      transformErrorResponse,
      query: (credentials: Forms.Login) => ({
        method: 'POST',
        body: credentials,
        url: ApiEndpoints.login,
      }),
    }),
    logout: builder.mutation<any, void>({
      transformErrorResponse,
      query: () => ({
        method: 'POST',
        url: ApiEndpoints.logout,
      }),
    }),
    register: builder.mutation<any, Forms.Register.Step2>({
      transformErrorResponse,
      query: ({ searchParams: sp, ...credentials }: Forms.Register.Step2) => ({
        method: 'POST',
        body: credentials,
        url: `${ApiEndpoints.registerStep2}${sp ? `?${sp}` : ''}`,
      }),
    }),
    resetPassword: builder.mutation<any, Forms.ResetPassword>({
      transformErrorResponse,
      query: (credentials: Forms.ResetPassword) => ({
        method: 'POST',
        body: credentials,
        url: ApiEndpoints.resetPassword,
      }),
    }),
    profileVerify: builder.mutation<any, void>({
      transformErrorResponse,
      query: () => ({
        method: 'POST',
        url: ApiEndpoints.profileVerify,
      }),
    }),
    checkUsername: builder.mutation<any, Forms.Register.StoreUsername>({
      transformErrorResponse,
      query: (credentials: Forms.Register.StoreUsername) => ({
        method: 'POST',
        body: credentials,
        url: ApiEndpoints.checkUsername,
      }),
    }),
    storeUsername: builder.mutation<any, Forms.Register.StoreUsername>({
      transformErrorResponse,
      query: (credentials: Forms.Register.StoreUsername) => ({
        method: 'POST',
        body: credentials,
        url: ApiEndpoints.storeUsername,
      }),
    }),
    forgotPassword: builder.mutation<any, Forms.ForgotPassword>({
      transformErrorResponse,
      query: (credentials: Forms.ForgotPassword) => ({
        method: 'POST',
        body: credentials,
        url: ApiEndpoints.forgotPassword,
      }),
    }),
    storeSocialAccounts: builder.mutation<any, Forms.SocialAccounts>({
      transformErrorResponse,
      query: (params) => ({
        body: params,
        method: 'POST',
        url: ApiEndpoints.registerStep3,
      }),
    }),
    verifyEmail: builder.mutation<any, string>({
      transformErrorResponse,
      query: (url) => ({
        body: { url },
        method: 'POST',
        url: `${ApiEndpoints.verifyEmail}`,
      }),
    }),
    getGoogleAuthUrl: builder.mutation<any, { username: string } | void>({
      transformErrorResponse,
      query: (params) => {
        if (params) {
          const searchParams = new URLSearchParams(params).toString();
          return {
            method: 'GET',
            url: `${ApiEndpoints.getGoogleAuthUrl}?${searchParams}`,
          };
        }

        return {
          method: 'GET',
          url: ApiEndpoints.getGoogleAuthUrl,
        };
      },
    }),
    googleAuthCallback: builder.mutation<any, any>({
      transformErrorResponse,
      query: (params) => {
        const searchParams = new URLSearchParams(params).toString();
        return {
          method: 'GET',
          url: `${ApiEndpoints.googleAuthCallback}?${searchParams}`,
        };
      },
    }),
    onboarding: builder.mutation<any, any>({
      transformErrorResponse,
      query: (body) => {
        return {
          body,
          method: 'POST',
          url: ApiEndpoints.onboarding,
        };
      },
    }),
  }),
});

export const {
  endpoints,
  useMeMutation,
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useOnboardingMutation,
  useVerifyEmailMutation,
  useProfileVerifyMutation,
  useCheckUsernameMutation,
  useResetPasswordMutation,
  useStoreUsernameMutation,
  useForgotPasswordMutation,
  useGetGoogleAuthUrlMutation,
  useGoogleAuthCallbackMutation,
  useStoreSocialAccountsMutation,
} = authApi;
