import { createSlice } from '@reduxjs/toolkit';
import { Countries } from '@/lib/types/countries';
import { endpoints } from '@/lib/services/countries';

const initialState: Countries.List = [];

const { fetchCountries } = endpoints;

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(fetchCountries.matchFulfilled, (_state, action) => {
      return action.payload.data.countries
        .map(({ name }) => ({
          value: name,
          label: name,
        }))
        .sort((a: { value: string; label: string }) => {
          if (['United States', 'United Kingdom'].includes(a.value)) {
            return -1;
          }

          return 0;
        });
    });
  },
});
