'use client';

import isPropValid from '@emotion/is-prop-valid';
import styled, { CSSObject } from '@emotion/styled';

export const Box = styled('div', {
  shouldForwardProp: (prop) =>
    isPropValid(prop) && !['boxStyles', 'mt'].includes(prop),
})<{
  boxStyles?: CSSObject;
  mt?: CSSObject['marginTop'];
}>(
  {
    width: '100%',
    display: 'flex',
  },
  ({ boxStyles, mt }) => ({
    marginTop: mt,
    ...boxStyles,
  }),
);
