import { CtaLabels } from '@shared/constants/ctaLabels';
import { BackgroundTypes } from '@shared/constants/background-types';

export const mkitDefaults = {
  content: {
    theme: {
      color_id: 1,
      palette_id: 1,
      primary_color: '#5956E9',
      bg_image_type: BackgroundTypes.Static,
      palette: '#071952,#0b666a,#35a29f,#97feed',
      shuffled_palette: '#071952,#0b666a,#35a29f,#97feed',
    },
    profile: {
      avatar_shape: 1,
      name: { value: '' },
      cta: CtaLabels['Partner with me'],
    },
  },
};
