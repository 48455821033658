import { api } from './api';
import { Builder } from '@/lib/types/builder';
import { urlHelper } from '@/lib/utils/urlHelper';
import { FontGroups } from '@/lib/types/builder/fontGroups';
import { ApiEndpoints } from '@shared/constants/apiEndpoints';
import { profileAvatarShapes } from '@shared/constants/profileSizes';
import { transformErrorResponse } from '@/lib/utils/transformErrorResponse';

interface FetchMediakitRT {
  data: { mediakit: Builder.Mediakit.Mediakit; user?: any; visits?: any };
}

interface EditMediakitQA {
  slug: string;
  bio?: string;
  location?: string;
  categories?: string;
  last_updated_at?: number;
  data: {
    profile: Partial<{
      cta: string;
      avatar_shape: profileAvatarShapes;
      name: Builder.CurrentMediakit.Name;
      social_links: Builder.CurrentMediakit.Profile['social_links'];
    }>;
    theme: Partial<Builder.Mediakit.Theme>;
    components: Builder.Mediakit.Component[];
  };
}

interface EditMediakitRT {
  data: { mediakit: Builder.Mediakit.Mediakit };
}

interface EnableMediakitRT {
  data: { mediakit: Builder.Mediakit.Mediakit };
}

interface CreateMediakitRT {
  data: { mediakit: Builder.Mediakit.Mediakit };
}

interface UploadAvatarQA {
  slug: string;
  data: FormData;
}

interface UploadAvatarRT {
  data: { image: string };
}

interface UploadImageRT {
  data: { image: string; uid: string };
}

interface UploadImageQA {
  slug: string;
  data: FormData;
}

interface LinkCrawlRT {
  uid: string;
  data: {
    link: string;
    title: string;
    domain: string;
    image_url: string;
    favicon_url: string;
  };
}

interface LinkCrawlQA {
  slug: string;
  link: string;
  uid: string;
}

interface FetchMediakitsRT {
  data: { mediakits: Builder.Mediakit.SimpleMediakit[] };
}

export interface FetchComponentsRT {
  data: {
    tags: Builder.Components.Tags;
    'bg-images': Builder.BgImage[];
    colors: Builder.Components.Colors;
    components: Builder.Components.Items;
    'bg-image-tags': Builder.BgImageTags;
    'font-groups': FontGroups;
  };
}

interface FetchThemeColorsRr {
  data: { colors: Builder.Theme.ItemsObject };
}

export const mediakitApi = api.injectEndpoints({
  endpoints: (builder) => ({
    enable: builder.mutation<EnableMediakitRT, string>({
      transformErrorResponse,
      query: (slug: string) => ({
        method: 'POST',
        url: urlHelper(ApiEndpoints.enableMediaKit, { slug }),
      }),
    }),
    linkCrawl: builder.mutation<LinkCrawlRT, LinkCrawlQA>({
      transformErrorResponse,
      query: ({ slug, link, uid }) => ({
        method: 'POST',
        body: { uid, link },
        url: urlHelper(ApiEndpoints.linkCrawl, { slug }),
      }),
    }),
    fetchMediakit: builder.mutation<FetchMediakitRT, string>({
      transformErrorResponse,
      query: (slug) => ({
        method: 'GET',
        url: urlHelper(ApiEndpoints.fetchMediakit, { slug }),
      }),
    }),
    fetchMediakits: builder.mutation<FetchMediakitsRT, void>({
      transformErrorResponse,
      query: () => ({
        method: 'GET',
        url: ApiEndpoints.fetchMediakits,
      }),
    }),
    createMediakit: builder.mutation<CreateMediakitRT, any>({
      transformErrorResponse,
      query: (body) => ({
        body,
        method: 'POST',
        url: ApiEndpoints.createMediakit,
      }),
    }),
    fetchComponents: builder.mutation<FetchComponentsRT, void>({
      transformErrorResponse,
      query: () => ({
        method: 'GET',
        url: ApiEndpoints.fetchComponents,
      }),
    }),
    uploadImage: builder.mutation<UploadImageRT, UploadImageQA>({
      transformErrorResponse,
      query: ({ slug, data }) => ({
        body: data,
        method: 'POST',
        formData: true,
        url: urlHelper(ApiEndpoints.uploadImage, { slug }),
      }),
    }),
    fetchThemeColors: builder.mutation<FetchThemeColorsRr, void>({
      transformErrorResponse,
      query: () => ({
        method: 'GET',
        url: ApiEndpoints.fetchThemeColors,
      }),
    }),
    uploadAvatar: builder.mutation<UploadAvatarRT, UploadAvatarQA>({
      transformErrorResponse,
      query: ({ slug, data }) => ({
        body: data,
        method: 'POST',
        formData: true,
        url: urlHelper(ApiEndpoints.uploadAvatar, { slug }),
      }),
    }),
    editMediakit: builder.mutation<EditMediakitRT, EditMediakitQA>({
      transformErrorResponse,
      query: (data) => ({
        body: data,
        method: 'PUT',
        url: urlHelper(ApiEndpoints.editMediakit, { slug: data.slug }),
      }),
    }),
    editMediakitFromTemplate: builder.mutation<
      any,
      {
        slug: string;
        templateId: number;
      }
    >({
      transformErrorResponse,
      query: (data) => ({
        body: data,
        method: 'PUT',
        url: urlHelper(ApiEndpoints.editMediakitFromTemplate, {
          slug: data.slug,
          templateId: data.templateId,
        }),
      }),
    }),
    fetchPublicMediakit: builder.mutation<FetchMediakitRT, string>({
      transformErrorResponse,
      query: (username) => ({
        method: 'GET',
        url: urlHelper(ApiEndpoints.fetchPublicMediakit, { username }),
      }),
    }),
    uploadBackgroundImage: builder.mutation<UploadImageRT, UploadImageQA>({
      transformErrorResponse,
      query: ({ slug, data }) => ({
        body: data,
        method: 'POST',
        formData: true,
        url: urlHelper(ApiEndpoints.uploadBackgroundImage, { slug }),
      }),
    }),
    fetchThemeColorPalettes: builder.mutation<
      any,
      { color: number; tag?: number; limit: number; offset: number }
    >({
      transformErrorResponse,
      query: ({ color, tag, limit, offset }) => {
        let q = `?color=${color}&limit=${limit}&offset=${offset}`;

        if (tag) {
          q = `${q}&tag=${tag}`;
        }

        return {
          method: 'GET',
          url: `${ApiEndpoints.fetchThemeColorPalettes}${q}`,
        };
      },
    }),
    themeUnsplashBgImages: builder.mutation<any, string>({
      transformErrorResponse,
      query: (q) => ({
        method: 'GET',
        url: `${ApiEndpoints.themeUnsplashBgImages}?${q}`,
      }),
    }),
  }),
});

export const {
  endpoints,
  useEnableMutation,
  useLinkCrawlMutation,
  useUploadImageMutation,
  useEditMediakitMutation,
  useUploadAvatarMutation,
  useFetchMediakitMutation,
  useCreateMediakitMutation,
  useFetchMediakitsMutation,
  useFetchComponentsMutation,
  useFetchThemeColorsMutation,
  useFetchPublicMediakitMutation,
  useThemeUnsplashBgImagesMutation,
  useUploadBackgroundImageMutation,
  useFetchThemeColorPalettesMutation,
  useEditMediakitFromTemplateMutation,
} = mediakitApi;
