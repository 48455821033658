export const transformErrorResponse = (baseQueryReturnValue: any) => ({
  status: baseQueryReturnValue.status,
  message: baseQueryReturnValue.data.message,
  errors: Object.keys(baseQueryReturnValue.data.errors || {}).reduce(
    (accumulator: { [key: string]: string }, currentValue) => {
      const [firstMessage] = baseQueryReturnValue.data.errors[currentValue];

      accumulator[currentValue] = firstMessage;

      return accumulator;
    },
    {},
  ),
});
