import Cookies from 'universal-cookie';
import { Auth } from '@/lib/types/auth';
import { endpoints } from '@/lib/services/auth';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VerificationStatuses } from '@shared/constants/verificationStatuses';

const cookies = new Cookies();
const access_token = cookies.get('access_token') ?? null;

const initialState: Auth.SliceState = {
  value: 0,
  access_token,
  status: 'idle',
  onboardingModal: false,
  verificationModal: false,
  tour: { step: 0, open: false },
};

const {
  me,
  login,
  logout,
  register,
  onboarding,
  profileVerify,
  storeUsername,
  googleAuthCallback,
  storeSocialAccounts,
} = endpoints;

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setMessage(state, action: Auth.Message) {
      state.message = action.payload;
    },
    setOpenTour(state, action: PayloadAction<boolean>) {
      state.tour.open = action.payload;
    },
    onUpdateTourStep(state, action: PayloadAction<number>) {
      state.tour.step = action.payload;
    },
    onUpdateAuthUser(state, action: PayloadAction<Partial<Auth.AuthUser>>) {
      if (state.authUser) {
        Object.keys(action.payload).forEach((key) => {
          (state.authUser as any)[key] = (action.payload as any)[key];
        });
      }
    },
    onToggleVerificationModal(state, action: PayloadAction<boolean | void>) {
      state.verificationModal = action.payload ?? !state.verificationModal;
    },
    onToggleOnboardingModal(state, action: PayloadAction<boolean | undefined>) {
      state.onboardingModal = action.payload ?? !state.onboardingModal;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(login.matchFulfilled, (state, action) => {
        state.access_token = action.payload.data.token;
      })
      .addMatcher(register.matchFulfilled, (state, action) => {
        state.access_token = action.payload.data.token;
      })
      .addMatcher(googleAuthCallback.matchFulfilled, (state, action) => {
        state.access_token = action.payload.data.token;
      })
      .addMatcher(me.matchFulfilled, (state, action) => {
        state.authUser = action.payload.data.user;

        state.authUser.shouldFetch = true;
      })
      .addMatcher(storeSocialAccounts.matchFulfilled, (state) => {
        if (state.authUser) {
          state.authUser.step = 3;
          state.authUser.showTemplates = true;
        }
      })
      .addMatcher(storeUsername.matchFulfilled, (state, action) => {
        if (state.authUser) {
          state.authUser.handle = action.meta.arg.originalArgs.username;
        }
      })
      .addMatcher(onboarding.matchFulfilled, (state, action) => {
        if (state?.authUser) {
          state.authUser.onboarding = action.payload.data.onboarding;
        }
      })
      .addMatcher(profileVerify.matchFulfilled, (state) => {
        if (state.authUser) {
          state.authUser.vm_verify = VerificationStatuses.VERIFY_REQUEST_SENT;
        }
      })
      .addMatcher(logout.matchFulfilled, () => {
        return { ...initialState, access_token: null };
      });
  },
});

export const {
  setMessage,
  setOpenTour,
  onUpdateTourStep,
  onUpdateAuthUser,
  onToggleOnboardingModal,
  onToggleVerificationModal,
} = authSlice.actions;
