import { api } from '@/lib/services/api';
import {
  appSlice,
  authSlice,
  builderSlice,
  categoriesSlice,
  countriesSlice,
} from './slices';

export const reducer = {
  app: appSlice.reducer,
  auth: authSlice.reducer,
  builder: builderSlice.reducer,
  countries: countriesSlice.reducer,
  categories: categoriesSlice.reducer,
  [api.reducerPath]: api.reducer,
};
