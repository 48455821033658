import { ReduxState } from '@shared/redux';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
  baseUrl: '/api',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = (getState() as ReduxState).auth.access_token;

    if (token) {
      headers.set('authentication', `Bearer ${token}`);
    }

    if (
      ['uploadAvatar', 'uploadImage', 'uploadBackgroundImage'].includes(
        endpoint,
      )
    ) {
      headers.delete('Content-Type');
    } else {
      if (!headers.get('Content-Type')) {
        headers.append('Content-Type', 'application/json');
      }
    }

    return headers;
  },
});

export const api = createApi({
  baseQuery,
  tagTypes: ['auth'],
  endpoints: () => ({}),
});
